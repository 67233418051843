import React, { useEffect, useState } from 'react'
import { fetchOnboardingJourneyKey, useOnboardingJourneyInfo } from '../../custom-hooks/useOnboardingJourneyQuery'
import PageLoading from '../../components/loading/PageLoading'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import NonDirectorPollingPageContent from './NonDirectorPollingPageContent'
import routes from '../../routes'
import { OnboardingJourneyStatus } from '../../types/enums/OnboardingJourneyStatus'
import { useQueryClient } from 'react-query'
import { FlowType } from '../../types/enums/FlowType'
import { OnboardingJourneySource } from '../../types/enums/OnboardingJourneySource'

const Redirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return null
}

const NonDirectorPollingPage = () => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data, dataLoading, error } = useOnboardingJourneyInfo(merchantId, merchantCompanyIdentifier)
  const [invalidateInProgress, setInvalidateInProgress] = useState(true)

  // We must invalidate the cache in order to pull the right status which is checked below
  // that's because the "current" (last retrieved) status is "CompanyCheckHasCompletedWithEligible"
  // and we can't allow the page to be shown for this status.
  // If we did and the requester somehow ended up on this page, they could potentially get stuck -
  // unable to provide director information
  useEffect(() => {
    queryClient
      .invalidateQueries(fetchOnboardingJourneyKey)
      .then(() => {
        setInvalidateInProgress(false)
      })
      .catch(() => navigate(routes.error))
  }, [setInvalidateInProgress, navigate, queryClient])

  if (dataLoading || data == null || invalidateInProgress) {
    return <PageLoading title="Getting everything ready..." body="Please don't close the window." />
  }

  // prevent people from accessing this page manually for OJs which aren't meant to use it
  if (error || data.flowType !== FlowType.LimitedCompany || data.source !== OnboardingJourneySource.PaymentJourney) {
    navigate(routes.error)
  }

  if (data.status == OnboardingJourneyStatus.OnboardingJourneyIsSuccessfullyCompleted) {
    return <Redirect url={data.completedUrl} />
  }

  if (
    data.status !== OnboardingJourneyStatus.ModularChecksAreInitialised &&
    data.status !== OnboardingJourneyStatus.ModularChecksAreInProgress
  ) {
    navigate(generatePath(routes.home, { merchantId, merchantCompanyIdentifier }))
  }

  return <NonDirectorPollingPageContent data={data} />
}

export default NonDirectorPollingPage
