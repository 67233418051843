import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import PageContainerContent from './PageContentContainer'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventType'

type EligibilityErrorProps = {
  onClick: () => void
  returnLink?: string
  merchantName?: string
}

const EligibilityError = ({ onClick, returnLink, merchantName }: EligibilityErrorProps) => {
  useTrackEvent(HotJarEventType.RISK_DECISION_DELAYED)

  return (
    <Backdrop
      sx={{
        background: theme.palette.common.white
      }}
      open={true}
    >
      <PageContainerContent>
        <Typography variant="h1" data-cy="title" textAlign="center">
          Oops... something went wrong.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'center'
          }}
        >
          <Link component="button" variant="caption" id="try-again" onClick={onClick}>
            Try again
          </Link>
          {returnLink && (
            <>
              <Typography variant="caption" data-cy="or" fontWeight={400} marginTop={theme.spacing(1)}>
                or
              </Typography>

              <Link
                component="button"
                variant="caption"
                id="return-to-merchant"
                onClick={() => {
                  window.location.replace(returnLink)
                }}
              >
                return to {merchantName}
              </Link>
            </>
          )}
        </Box>
      </PageContainerContent>
    </Backdrop>
  )
}

export default EligibilityError
