export enum HotJarEventType {
  RISK_REVIEW = 'onboarding_risk_review',
  RISK_REJECTED = 'onboarding_risk_rejected',
  RISK_DECISION_DELAYED = 'onboarding_risk_decision_delayed',
  INVALID_LINK = 'onboarding_invalid_link',
  SOLE_TRADER_CONFLICT = 'onboarding_sole_trader_conflict',
  UNEXPECTED_ERROR = 'onboarding_unexpected_error',
  MANUAL_ADDRESS_INPUT = 'onboarding_manual_address_input',
  ONBOARDING_REVIEW = 'onboarding_checks_review',
  CHECKS_FAILED = 'onboarding_checks_failed',
  ONBOARDING_SUCCESS = 'onboarding_outcome_success',
  DIRECT_DEBIT_LINK_CLICKED = 'onboarding_direct_debit_link_clicked'
}
