import Link from '@mui/material/Link'
import ErrorPageContainer from './ErrorPageContainer'
import Box from '@mui/material/Box'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventType'

const NotFound = () => {
  useTrackEvent(HotJarEventType.INVALID_LINK)

  return (
    <ErrorPageContainer title="Link not found">
      <Box data-cy="message">Hmm… You’ve entered an invalid link. Please try again.</Box>
      <br />
      <Box data-cy="contact-details">
        If you need any assistance, please contact{' '}
        <Link href="mailto:customersupport@kriya.co">customersupport@kriya.co</Link>.
      </Box>
    </ErrorPageContainer>
  )
}

export default NotFound
