import Box from '@mui/material/Box'
import { Content } from '../../assets/styles/ContentStyles'
import Link from '@mui/material/Link'
import Logo from '../../components/Logo'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import BoxedContent from '../../components/BoxedContent'
import ActionButton from '../../components/ActionButton'
import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { OnboardingJourneySource } from '../../types/enums/OnboardingJourneySource'
import { useQueryClient } from 'react-query'
import { fetchOnboardingJourneyKey } from '../../custom-hooks/useOnboardingJourneyQuery'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import routes from '../../routes'
import { StyledButtonsContainer } from '../../components/layout/Container'
import ReturnToMartketplaceDialog from '../../components/returnToMarketplace'

const copiedLinkTimeout = 5000

const DirectorStepCompleted = ({
  merchantName,
  returnUrl,
  directorName,
  logoUri,
  source
}: {
  merchantName: string
  returnUrl?: string
  directorName: string
  logoUri?: string
  source?: string
  flowType?: string
}) => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const handleReturnClick = () => {
    window.location.replace(returnUrl!)
  }

  const onLinkCopy = () => {
    navigator.clipboard
      .writeText(window.location.toString())
      .then(() => {
        setIsLinkCopied(true)
        setTimeout(() => setIsLinkCopied(false), copiedLinkTimeout)
      })
      .catch(() => {
        setIsLinkCopied(false)
      })
  }

  const onContinue = () => {
    navigate(
      generatePath(routes.requester, {
        merchantId,
        merchantCompanyIdentifier
      }),
      { replace: true }
    )
  }

  const onStart = async () => {
    //Force re-fetching the onboarding journey to get the latest status and trigger different component render
    await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
  }

  return (
    <Box>
      {logoUri ? <Logo logoUri={logoUri}></Logo> : <br />}
      <Content
        sx={{
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(logoUri ? 24 : 16)
          },
          [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(logoUri ? 32 : 16)
          }
        }}
      >
        <Typography variant="h3" data-cy="title">
          Thank you for providing {directorName}’s email.
        </Typography>
        <Typography
          variant="body2"
          data-cy="message"
          sx={{
            [theme.breakpoints.down('sm')]: {
              fontSize: '16px'
            },
            marginBottom: theme.spacing(16)
          }}
        >
          The chosen director will shortly receive an email with a link to start the onboarding process. They will be
          required to submit both an ID photo and a selfie. Once onboarding is completed successfully, you'll get a
          confirmation email, enabling you to proceed with the checkout.
        </Typography>

        <BoxedContent title="If the selected director is yourself">
          <Typography variant="body2" data-cy="director-content-message" sx={{ marginTop: theme.spacing(-8) }}>
            Please ensure you have a valid ID ready. The process will only take a few minutes. Once you successfully
            pass onboarding, you'll be able to proceed directly to the checkout.
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <ActionButton onClick={onStart} id="start-director-onboarding-btn">
                Start
              </ActionButton>
            </Grid>
          </Grid>
        </BoxedContent>

        <BoxedContent title="If the selected director is another person">
          <Typography variant="body2" data-cy="another-person-content-message" sx={{ marginTop: theme.spacing(-8) }}>
            For a quicker process, you can forward the onboarding link to your director and exit this page. Upon
            successful onboarding, we'll email you a link to proceed with the checkout.
          </Typography>

          <Grid
            container
            sx={{
              [theme.breakpoints.down('sm')]: {
                gap: theme.spacing(6)
              }
            }}
            columnSpacing={{ sm: 0, md: 6 }}
          >
            <Grid item flexGrow="1" xs={12} md={10}>
              <TextField
                InputProps={{
                  readOnly: true
                }}
                sx={{ width: '100%' }}
                value={window.location}
                onClick={(e) => (e.target as HTMLInputElement).select()}
              ></TextField>
            </Grid>
            <Grid item alignSelf={'center'} xs={12} md={2}>
              {!isLinkCopied && (
                <Button variant={'dialog'} sx={{ width: '100%' }} onClick={onLinkCopy} id="copy-link-btn">
                  Copy
                </Button>
              )}

              {isLinkCopied && (
                <Typography sx={{ width: '100%', textAlign: 'center' }} variant={'subtitle1'}>
                  Copied
                </Typography>
              )}
            </Grid>
          </Grid>

          {source !== OnboardingJourneySource.MarketplacePortal && (
            <Typography variant="helperText2" data-cy="another-person-helper-message">
              Once you've shared the link with your director, feel free to close this page{returnUrl ? ' or ' : ''}
              {returnUrl && (
                <Link
                  id="return-to-merchant"
                  onClick={handleReturnClick}
                  sx={{
                    color: theme.palette.common.textGrey600,
                    cursor: 'pointer'
                  }}
                >
                  navigate back to {merchantName}
                </Link>
              )}
              . Upon successful completion of director onboarding, you will receive an email containing a link to resume
              the checkout process.
            </Typography>
          )}
          {source === OnboardingJourneySource.MarketplacePortal && (
            <Typography variant="helperText2" data-cy="another-person-helper-message">
              Once you've shared the link with your director, feel free to close this page. Upon successful completion
              of director onboarding, you will receive an email containing a link to resume the checkout process.
            </Typography>
          )}
        </BoxedContent>
        {source === OnboardingJourneySource.PaymentJourney && (
          <StyledButtonsContainer
            sx={{
              marginTop: theme.spacing(16)
            }}
          >
            <ActionButton id="continue-button" disabled={false} onClick={onContinue}>
              Continue
            </ActionButton>
            {returnUrl && (
              <ReturnToMartketplaceDialog
                marketplace={merchantName}
                disabled={false}
                redirectUrl={returnUrl}
              ></ReturnToMartketplaceDialog>
            )}
          </StyledButtonsContainer>
        )}
      </Content>
    </Box>
  )
}

export default DirectorStepCompleted
