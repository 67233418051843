import ErrorPageContainer from './ErrorPageContainer'
import React from 'react'
import Box from '@mui/material/Box'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { HotJarEventType } from '../../types/enums/HotJarEventType'

const CheckShouldBeReRequested = () => {
  useTrackEvent(HotJarEventType.UNEXPECTED_ERROR)

  return (
    <ErrorPageContainer title="The link is no longer valid">
      <Box data-cy="message">Something's gone wrong. Please start the onboarding process again.</Box>
    </ErrorPageContainer>
  )
}

export default CheckShouldBeReRequested
