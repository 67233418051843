import { Controller, useForm } from 'react-hook-form'
import { maxWidthLargeDevices, minWidthSmallDevices } from '../../../assets/styles/ContentStyles'

import ActionButton from '../../../components/ActionButton'
import Box from '@mui/material/Box'
import { Company } from '../../../types/Company'
import DirectorStepCompleted from '../DirectorStepCompleted'
import DirectorStepContent from './DirectorStepContent'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import MenuItem from '@mui/material/MenuItem'
import { OnboardingJourney } from '../../../types/OnboardingJourney'
import { Person } from '../../../types/Person'
import TextField from '@mui/material/TextField'
import TextInput from '../../../components/TextInput'
import { emailPattern } from '../../../utils/emailRegExp'
import theme from '../../../assets/theme'
import { useState } from 'react'
import { useUpdateOnboardingJourney } from '../../../custom-hooks/useUpdateOnboardingJourney'
import Logo from '../../../components/Logo'
import ReturnToMartketplaceDialog from '../../../components/returnToMarketplace'
import { StyledButtonsContainer } from '../../../components/layout/Container'
import { OnboardingJourneySource } from '../../../types/enums/OnboardingJourneySource'
import { maxLength150 } from '../../../utils/validationConstants'
import styled from '@emotion/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export type DirectorForm = {
  requester?: Person
  company?: Company
  director?: Person
}

const captionBoxStyling = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
  p: {
    sm: theme.spacing(10, 0, 0),
    xs: theme.spacing(6, 0, 0)
  }
}

const StyledBox = styled(Stack)`
  padding: ${theme.spacing(10, 13)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(5)};
  border: 1px solid ${theme.palette.common.buttonColorOnDisabled};
  background: ${theme.palette.common.grey10};
  border-radius: ${theme.spacing(3)};
`

const DirectorStep = (data: OnboardingJourney) => {
  const { company, riskDecision, requester } = data
  const [completed, setCompleted] = useState(false)
  const [directorName, setDirectorName] = useState<string>('')

  const form = useForm<DirectorForm>({
    mode: 'onChange',
    defaultValues: {
      company: {
        name: company?.name || '',
        kriyaCompanyIdentifier: company?.kriyaCompanyIdentifier || '',
        directors: company.directors || ''
      },
      requester: {
        email: requester?.email || '',
        fullName: requester?.fullName || '',
        phoneNumber: requester?.phoneNumber || ''
      },
      director: {
        email: '',
        fullName: ''
      }
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = form

  const { mutate: saveDirectorMutation, isLoading: isMutationLoading } = useUpdateOnboardingJourney({
    onboardingJourneyId: data.id,
    onSuccess: (data) => {
      setDirectorName(data.director.fullName)
      setCompleted(true)
    }
  })

  const onSubmit = (formValues: DirectorForm) => {
    if (!isMutationLoading) {
      const directors = formValues.company?.directors
      const director = Array.isArray(directors) ? directors[0] : directors

      saveDirectorMutation({
        companyId: formValues.company?.kriyaCompanyIdentifier,
        requester: {
          email: formValues.requester?.email ?? '',
          fullName: formValues.requester?.fullName ?? '',
          phoneNumber: formValues.requester?.phoneNumber ?? ''
        },
        director: {
          email: formValues.director?.email ?? '',
          fullName: director ?? ''
        }
      })
    }
  }

  return (
    <Box
      sx={{
        maxWidth: { sm: maxWidthLargeDevices },
        minWidth: { xs: minWidthSmallDevices }
      }}
    >
      {completed ? (
        <DirectorStepCompleted
          directorName={directorName}
          merchantName={data.merchant.name}
          logoUri={data.merchant.logoUri}
          returnUrl={data.inReviewUrl}
          source={data.source}
        />
      ) : (
        <>
          {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
          <DirectorStepContent riskDecision={riskDecision} company={company} />
          <form onSubmit={handleSubmit(onSubmit)} id="director-information-form">
            <Box sx={captionBoxStyling}>
              <FormControl>
                <FormLabel>Select a director</FormLabel>
                <Controller
                  name="company.directors"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} id="select-directors" select>
                      {company.directors?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>

              <TextInput
                label="Director’s email"
                name="director.email"
                register={register}
                error={errors.director?.email}
                validation={{
                  required: 'Please enter your email address',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email address'
                  },
                  maxLength: maxLength150
                }}
              />

              <StyledBox>
                <Typography variant="helperText2">
                  Should the director be unable to complete onboarding promptly, please email us at{' '}
                  {
                    <Link href="mailto:customersupport@kriya.co" sx={{ color: theme.palette.common.info }}>
                      customersupport@kriya.co
                    </Link>
                  }
                  . Our team will assist you in completing alternative onboarding checks as soon as possible.
                </Typography>
              </StyledBox>

              <StyledButtonsContainer
                sx={{
                  marginTop: theme.spacing(16)
                }}
              >
                <ActionButton id="continue-button" disabled={!form.formState.isValid} loading={isMutationLoading}>
                  Submit
                </ActionButton>
                {data.source !== OnboardingJourneySource.MarketplacePortal && data.abandonedUrl && (
                  <ReturnToMartketplaceDialog
                    marketplace={data.merchant.name}
                    disabled={isMutationLoading}
                    redirectUrl={data.abandonedUrl}
                  ></ReturnToMartketplaceDialog>
                )}
              </StyledButtonsContainer>
            </Box>
          </form>
        </>
      )}
    </Box>
  )
}

export default DirectorStep
