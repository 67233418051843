import React from 'react'
import Box from '@mui/material/Box'
import { maxWidthLargeDevices, minWidthSmallDevices } from '../../../../assets/styles/ContentStyles'
import Logo from '../../../../components/Logo'
import { StyledButtonsContainer } from '../../../../components/layout/Container'
import ActionButton from '../../../../components/ActionButton'
import { OnboardingJourneySource } from '../../../../types/enums/OnboardingJourneySource'
import ReturnToMarketplaceDialog from '../../../../components/returnToMarketplace'
import DirectorSoleTraderStartPageContent from './DirectorSoleTraderStartPageContent'
import { OnboardingJourney } from '../../../../types/OnboardingJourney'
import { useUpdateOnboardingJourneyStatus } from '../../../../custom-hooks/useUpdateOnboardingJourneyStatus'
import { OnboardingJourneyStatus } from '../../../../types/enums/OnboardingJourneyStatus'
import { useForm } from 'react-hook-form'
import { useSaveCheckDetails } from '../../../../custom-hooks/useSaveCheckDetails'
import { CheckType } from '../../../../types/enums/CheckType'

const captionBoxStyling = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
  p: 0
}

export interface DirectorContactForm {
  termsAndConditions?: boolean
}

const DirectorSoleTraderStartPage = (data: OnboardingJourney) => {
  const { company, riskDecision } = data

  const { mutate: updateJourneyStatus, isLoading: isUpdateJourneyStatusLoading } = useUpdateOnboardingJourneyStatus({
    onboardingJourneyId: data.id
  })

  const { mutateAsync: saveCheckMutationAsync, isLoading: isCheckUpdateInProgress } = useSaveCheckDetails({
    onboardingJourneyId: data.id,
    checkType: CheckType.SignedTerms
  })

  const isMutationLoading = isUpdateJourneyStatusLoading

  const hasCheckOfType = (checkType: CheckType) => (data.checks?.filter((r) => r.type === checkType).length || 0) > 0

  const onSubmit = () => {
    if (!hasCheckOfType(CheckType.SignedTerms)) {
      updateJourneyStatus({ newStatus: OnboardingJourneyStatus.ModularChecksAreInProgress })
    } else {
      void saveCheckMutationAsync({}).then(() => {
        updateJourneyStatus({ newStatus: OnboardingJourneyStatus.ModularChecksAreInProgress })
      })
    }
  }

  const form = useForm<DirectorContactForm>({
    mode: 'onChange'
  })

  return (
    <Box
      sx={{
        maxWidth: { sm: maxWidthLargeDevices },
        minWidth: { xs: minWidthSmallDevices }
      }}
    >
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <form id="director-form" style={{ rowGap: captionBoxStyling.rowGap, display: 'grid' }}>
        <DirectorSoleTraderStartPageContent
          riskDecision={riskDecision}
          company={company}
          form={form}
          hasCheckOfType={hasCheckOfType}
        />
        <Box sx={captionBoxStyling}>
          <StyledButtonsContainer
            sx={{
              marginTop: 0
            }}
          >
            <ActionButton
              id="continue-button"
              disabled={!form.formState.isValid || isMutationLoading || isCheckUpdateInProgress}
              loading={isMutationLoading || isCheckUpdateInProgress}
              onClick={onSubmit}
            >
              Continue
            </ActionButton>
            {data.source !== OnboardingJourneySource.MarketplacePortal && data.abandonedUrl && (
              <ReturnToMarketplaceDialog
                marketplace={data.merchant.name}
                disabled={isMutationLoading || isCheckUpdateInProgress}
                redirectUrl={data.abandonedUrl}
              ></ReturnToMarketplaceDialog>
            )}
          </StyledButtonsContainer>
        </Box>
      </form>
    </Box>
  )
}

export default DirectorSoleTraderStartPage
